import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from "react-helmet";
import Layout from 'components/Layout';
import BlogPost from 'components/pages/BlogPost';
import { Image } from 'components/pages/BlogPost/styled';
import { ImgGatsbyBackground } from 'components/wrappers';
import styled, { css } from 'styled-components';
import { blogPosts } from 'common/blogPostsFeatured';
import BlogBaseCard from 'components/blog/BaseCard';
import BlogSidebar from 'components/blog/BlogSidebar';
import {PostsWrapper} from 'components/pages/Blog/styled';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import FacebookDefaultIcon from 'images/social-share-icons/facebook-white.png';
import TwitterDefaultIcon from 'images/social-share-icons/twitter-white.png';
import LinkedinDefaultIcon from 'images/social-share-icons/linkedin-white.png';
import BlogOptin from 'components/blog/BlogOptin';
import {Clear, BlogRow, ShareButton, ReadNow, DateText, TitleText, UnorderedList, OrderedList, FirstTitle, BottomShare, SectionFull, OptinSection, ColumnHalf, ColumnHalfLast} from 'components/blog/SingleBlogStyle';

import VATImg from 'images/blog-images/vat-image.png';
import AmazonCompanyImg from 'images/blog-images/amazon.png';
import UKEcommerceReformsFeatured from 'images/featured-image/vat-image.png';
import ShopifyImg from 'images/blog-images/shopify.png';

interface OwnProps {
  location: {
    href: string;
  };
}

const BlogArticle = ({ location }: OwnProps) => {
  const data = useStaticQuery(graphql`
    query UKEcommerceReforms {
      file(relativePath: { eq: "vat-image.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const BackgroundImage = () => <ImgGatsbyBackground fluid={data.file.childImageSharp.fluid} alt="2021 uk ecommerce reforms"/>;
  const { href } = location;
  const intl = useIntl()
  return (
    <Layout>
      <Helmet 
        // title="What You Need to Know About the 2021 Key eCommerce Reforms in the UK (2) | Shipkoo"
        title={intl.formatMessage({ id: "blog-post-meta-title-what-you-need-to-know-about-2021-uk-2" })}
         meta={
          [
            {
              name: `description`,
              content: "Learn more about the 2021 UK eCommerce Reforms. Goods entering the UK must pay customs duties and there is no exemption.",
            },
            {
              property: `og:title`,
              // content: "2021 UK eCommerce Reforms What You Need to Know",
              content: intl.formatMessage({ id: "blog-post-meta-title-what-you-need-to-know-about-2021-uk-2" }),
            },
            {
              property: `og:description`,
              content: "Learn more about the 2021 UK eCommerce Reforms. Goods entering the UK must pay customs duties and there is no exemption.",
            },
            {
              property: `og:image`,
              content: UKEcommerceReformsFeatured,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
          ] as JSX.IntrinsicElements['meta'][]
        }
      />
      <BlogPost
        location={location}
        id="2021-uk-ecommerce-reforms"
        title={intl.formatMessage({ id: "2021-uk-ecommerce-reforms-head-title" })}
        date="2020-12-23"
        author="Joe Zhang"
        Background={BackgroundImage}
      >
        <BlogRow>
          <BlogSidebar/>
          <div className="column-half last">

            <p>
              All goods entering the UK must pay customs duties and there is no exemption. All goods entering the UK must provide the corresponding VAT number for the customs inspection (currently there is no specific inspection plan, so the same delivery model as before is still maintained. )
            </p>

            <p>
              For goods over 135 pounds, customs clearance is carried out by general trade clearance, which is the customs clearance method mentioned in our previous blog.
            </p>

            <p>
              This plan will be integrated with the platform, which has regulatory obligations, and the platform also completes the obligation to collect VAT as the subject of the declaration.
            </p>

            <Image src={VATImg} className="" alt="value added tax"/>

            <h5>
              <strong>
                eCommerce Platforms
              </strong>
            </h5>

            <p>
              The platform will charge an additional 20% VAT fee. Some platforms will show this fee separately to the customer, and some will show it in the original price. This part of the fee needs to be paid by the buyer.
            </p>

            <p>
              The platform seller needs to provide the corresponding VAT during customs clearance.
            </p>

            <p>
              After the platform needs to be supervised and collected, the platform shall be the main body and a unified declaration shall be made, and the data of all platforms shall be stored for 6 years for data inspection
            </p>

            <Image src={AmazonCompanyImg} alt="amazon" />

            <h5>
              <strong>
                Standalone sites
              </strong>
            </h5>

            <p>
              Sellers need VAT to declare independence as the subject of the platform. The declaration is carried out by the entity of the enterprise, with a single corresponding declaration.
            </p>

            <p>
              For example:<br/>
              If the seller has one business entity and has opened N independent standalone sites, then only one business entity’s VAT is required.
            </p>

            <p>
              If the seller has N main enterprises and has opened N independent standalone sites, then they need to provide one-by-one corresponding VAT for declaration
            </p>

            <Image src={ShopifyImg} alt="shopify"/>

            <h5>
              <strong>
                Positive
              </strong>
            </h5>

            <UnorderedList>
              <li>
                It is good for independent standalone sites, especially standalone sites that are good for Vertical E-Business. Relative to eCommerce platforms, independent standalone sites may have  room for tax evasion, but eCommerce platforms are basically choked to death, compared to  independent standalone sites with a single enterprise subject. VAT The declaration and other procedures will be simpler
              </li>
              <li>
                Good news for local sellers: Local sellers have always paid corresponding tariffs. Compared with the main business owners, the tariffs will be lower, so price competition will also have relative advantages
              </li>
              <li>
                Faster shipping lead time: Because most of the goods have already been charged for tariffs, there is no need to review tariffs in depth, which improves the timeliness of customs clearance and withdrawal, etc., and because of the high tariffs, it is impossible to enter other countries. Therefore, the timeliness guarantee will be improved
              </li>
              <li>
                The price of large cargo flow is higher, which is good for dropshipping, and the relative cost will be reduced
              </li>
            </UnorderedList>

            <h5>
              <strong>
                Influences
              </strong>
            </h5>

            <UnorderedList>
              <li>
                The cost of the major platforms will rise by more than 20%, which will cause some customers to choose other platforms
              </li>
              <li>
                Tax compliance will cause companies that have been operating with low profits and will face greater financial pressure
              </li>
              <li>
                The platform will tilt more traffic to brand companies and suppress low-price acquisition of traffic
              </li>
              
            </UnorderedList>

            <BottomShare>
              <div className="column-half">
                <p>{intl.formatMessage({ id: "share-the-article-text" })}</p>
              </div>
              <div className="column-half last">
                <FacebookShareButton url={href}>
                  <ShareButton><img src={FacebookDefaultIcon} alt="FacebookDefaultIcon" /> <span>Share</span></ShareButton>
                </FacebookShareButton>
                <TwitterShareButton url={href}>
                  <ShareButton><img src={TwitterDefaultIcon} alt="TwitterDefaultIcon" /> <span>Tweet</span></ShareButton>
                </TwitterShareButton>
                <LinkedinShareButton url={href}>
                  <ShareButton><img src={LinkedinDefaultIcon} alt="LinkedinDefaultIcon" /> <span>Share</span></ShareButton>
                </LinkedinShareButton>
              </div>
              <Clear></Clear>
            </BottomShare> 
          </div>  
          <Clear></Clear>
       </BlogRow> 
      </BlogPost>
      <SectionFull>
        <BlogRow>
          <h2>More From Shipkoo</h2>
          <PostsWrapper>
            {blogPosts.map(post => (
              <BlogBaseCard key={`${post.title}-${post.id}`} post={post} />
            ))}
          </PostsWrapper>
        </BlogRow>
      </SectionFull>
      <BlogOptin />
    </Layout>
  );
};

export default BlogArticle;